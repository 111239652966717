import React, {useCallback, useEffect, useState} from 'react';
import {Container, Stack, Table} from "react-bootstrap";
import {loadExecutionStages} from "../../../../util/restClient";
import ExecutionStateControl from "../state/ExecutionStateControl";
import {ExecutionStageDataCollection} from "../../../../type/ExecutionStageData";
import LoadingBlock from "../../LoadingBlock";
import {ControlWithExecutionIdProps} from "../../../../type/BasicComponentProps";
import DurationControl from "../../DurationControl";
import ExecutionStateProgressControl from "../state/ExecutionStateProgressControl";
import SmartTextBox from "../../SmartTextBox";

export type ExecutionDetailStagesControlProps = ControlWithExecutionIdProps & {
	defaultText?: string | null;
};

function ExecutionDetailStagesControl({defaultText, executionId, eventManager}: ExecutionDetailStagesControlProps) {
	const [stages, setStages] = useState<ExecutionStageDataCollection | null>(null);

	const reloadAll = useCallback(
		() => {
			if (executionId === null) {
				setStages(null);
				return;
			}
			loadExecutionStages(executionId)
				.then(
					setStages,
					(err) => eventManager.err(String(err))
				)
		},
		[executionId, eventManager]
	);

	useEffect(
		() => {
			reloadAll();
			const handler = setInterval(reloadAll, 3000);
			return () => {
				clearInterval(handler);
			}
		},
		[executionId]
	);

	if (!(stages || defaultText)) {
		return <LoadingBlock/>;
	}

	return (
		<Container>
			{
				stages && stages.length > 0 ? (
					<Table responsive className="cool-table">
						<tbody>
							{
								stages.map(
									(s) => <tr key={s.id}>
										<td>
											<small className="text-muted"><DurationControl ms={s.durationMs}/></small>
										</td>
										<td>{s.stageName}</td>
										<td>
											<div className="text-center">
												<ExecutionStateControl state={s.executionState}/>
											</div>
										</td>
										<td>
											<Stack direction="horizontal" gap={2} className="justify-content-center">
												<ExecutionStateProgressControl progress={s.progress} executionState={s.executionState}/>
											</Stack>
										</td>
										<td><small className="text-muted">{s.message}</small></td>
									</tr>
								)
							}
						</tbody>
					</Table>
				) : (
					<SmartTextBox text={defaultText}/>
				)
			}
		</Container>
	);
}

export default ExecutionDetailStagesControl;
