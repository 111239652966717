import React from 'react';
import {Stack} from 'react-bootstrap';
import ExecutionStateControl from "../state/ExecutionStateControl";
import {ExecutionResult} from "../../../../type/ExecutionData";
import {StackDirection} from "react-bootstrap/Stack";
import ExecutionStateProgressControl from "../state/ExecutionStateProgressControl";

export type ExecutionResultProgressControlProps = {
	executionResult: ExecutionResult;
	direction?: StackDirection;
	gap?: number;
};

function ExecutionResultProgressControl({gap, executionResult, direction = 'horizontal'}: ExecutionResultProgressControlProps) {
	const actualGap = gap === undefined ? (direction === 'horizontal' ? 3 : 1) : gap;
	return <Stack direction={direction} gap={actualGap}>
		<ExecutionStateControl state={executionResult.executionState}/>
		<div className="flex-grow-1">
			<ExecutionStateProgressControl executionState={executionResult.executionState} progress={executionResult.progress} gap={gap}/>
		</div>
	</Stack>
}

export default ExecutionResultProgressControl;
